<div class="topnav">
    <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
            <div class="collapse navbar-collapse active" id="topnav-menu-content">
                <ul class="navbar-nav">
                    <ng-container *ngFor="let item of menu">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle arrow-none" (click)="goToPageByLink(item.link)"
                                id="topnav-components" role="button">
                                <i class="bx {{item.icon}} mr-2"></i>{{ item.label | translate }} <div
                                    class="arrow-down" *ngIf="hasItems(item)"></div>
                            </a>
                            <div class="dropdown-menu row" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                                    <a class="col dropdown-item side-nav-link-ref" *ngIf="!hasItems(subitem)"
                                        [routerLink]="subitem.link"
                                        routerLinkActive="active">{{subitem.label | translate }}</a>

                                    <div class="dropdown" *ngIf="hasItems(subitem)">
                                        <a class="dropdown-item"
                                            (click)="onMenuClick($event)">{{ subitem.label | translate }}
                                            <div class="arrow-down"></div>
                                        </a>
                                        <div class="dropdown-menu">
                                            <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                                                <a class="dropdown-item side-nav-link-ref"
                                                    [routerLink]="subSubitem.link"
                                                    routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                                            </ng-template>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </li>
                    </ng-container>

                </ul>
            </div>
        </nav>
    </div>
</div>