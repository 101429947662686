<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm custom">
            <!-- EasyHMIS -->
            <img src="assets/images/CHU _Casa_logo_light.png" alt="" height="22">
          </span>
          <span class="logo-lg custom">
            <!-- EasyHMIS -->
            <img src="assets/images/CHU _Casa_logo_light.png" alt="" height="17">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm custom">
            <!-- EasyHMIS -->
            <img src="assets/images/CHU _Casa_logo_light.png" alt="" height="22">
          </span>
          <span class="logo-lg custom">
            <!-- EasyHMIS -->
            <img src="assets/images/CHU _Casa_logo_light.png" alt="" height="32">
          </span>
        </a>
      </div>

      <span class="py-4" style="margin-top: 2px;">
        <i class="fas fa-ellipsis-v"></i>
      </span>
      <!-- <button class="btn px-3 font-size-16 header-item" style="margin-top: 2px;" (click)="gotoDesk()">
        <i class="fa fa-fw fa-desktop"></i>
      </button> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <i class="fa fa-fw fa-desktop font-size-16"></i>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/admin/dashboard']">
            Admin
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/pharmacy/dashboard']">
            Pharmacy
          </a>
          <a class="dropdown-item d-block" href="javascript: void(0);" [routerLink]="['/pages/nurse/op/dashboard']">
            Nurse OP
          </a>
          <a class="dropdown-item d-block" href="javascript: void(0);" [routerLink]="['/pages/nurse/ip/dashboard']">
            Nurse IP
          </a>
          <a class="dropdown-item d-block" href="javascript: void(0);" [routerLink]="['/pages/lab/dashboard']">
            Lab
          </a>
          <a class="dropdown-item d-block" href="javascript: void(0);" [routerLink]="['/pages/billing/dashboard']">
            Biller
          </a>
          <a class="dropdown-item d-block" href="javascript: void(0);" [routerLink]="['/pages/accounts/dashboard']">
            Accounts
          </a>
          <a class="dropdown-item d-block" href="javascript: void(0);" [routerLink]="['/pages/doctor/dashboard']">
            Doctor
          </a>
          <a class="dropdown-item d-block" href="javascript: void(0);" [routerLink]="['/pages/emergency/dashboard']">
            Emergency
          </a>
          <a class="dropdown-item d-block" href="javascript: void(0);" [routerLink]="['/pages/insurance/dashboard']">
            Insurance
          </a>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <span class="d-xl-inline-block">{{ 'HEADER.MENU_PEOPLE' | translate}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block ml-1"></i>
        </button>
        <div class="dropdown-menu dropdown-menu" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/patient']">
            Patients
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/referrals']">
            Referrals
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/doctor']">
            Doctors
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/employee']">
            Employees
          </a>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <span class="d-xl-inline-block">{{ 'HEADER.MENU_ADMISSION' | translate}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block ml-1"></i>
        </button>
        <div class="dropdown-menu dropdown-menu" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/admission/requests']">
            {{ 'HEADER.MAIN_MENU.ADMISSION_MENU.REQUESTS' | translate}}
          </a>
          <!-- <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/admission/requests']">
            Requests (Nurse)
          </a> -->
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/admission/admissions']">
            {{ 'HEADER.MAIN_MENU.ADMISSION_MENU.INPATIENT' | translate}}
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/admission/bedtransfer']">
            {{ 'HEADER.MAIN_MENU.ADMISSION_MENU.BED_TRANSFER' | translate}}
          </a>
        </div>
      </div>


      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <span class="d-none d-xl-inline-block">{{ 'HEADER.MAIN_MENU.APPOINTMENTS' | translate}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block ml-1"></i>
        </button>
        <div class="dropdown-menu dropdown-menu" ngbDropdownMenu>
          <a class="dropdown-item d-none" href="javascript: void(0);" [routerLink]="['/pages/appointment/requests']">
            Requests
          </a>
          <a class="dropdown-item d-none" href="javascript: void(0);"
            [routerLink]="['/pages/appointment/speciality/requests']">
            Speciality Requests
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/appointment/visit/schedules']">
            Appointment List
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/appointment/consolidated']">
            Consolidated View
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/appointment/booking']">
            Bookings
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/appointment/remarks']">
            Remarks
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/appointment/calendar/configuration']">
            Calendar Configuration
          </a>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <span class="d-none d-xl-inline-block">{{ 'HEADER.MAIN_MENU.BILLING' | translate}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block ml-1"></i>
        </button>
        <div class="dropdown-menu dropdown-menu" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/billing/bills']">
            Bills
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/billing/quote/list']">
            Quotes
          </a>
          <!-- <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/billing/insurance/list']">
            Insurance
          </a> -->
          <!-- ----------------------------Cusom made sub items menuss -->
          <div class="dropdown d-inline-block" ngbDropdown>
            <a type="button" class="btn custom-drop" id="page-header-user-dropdown" ngbDropdownToggle>
              <span class="d-xl-inline-block">Organization</span>
              <i class="mdi mdi-chevron-right d-none d-xl-inline-block ml-1"></i>
            </a>
            <div class="dropdown-menu dropdown-menu custom-placement" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/billing/insurance/list']">
                Invoices
              </a>
              <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/billing/batch/list']">
                Batch
              </a>
              <!-- <div class="dropdown-divider"></div> -->
              <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/billing/insurance/status']">
                Insurance Status Update
              </a>
              <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/billing/receipts']">
                Receipts
              </a>
            </div>
          </div>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/billing/recovery/list']">
            Recovery
          </a>
   


          
          <!-- <div class="dropdown-divider"></div>
          <a class="dropdown-item">
            <b>Configuration</b>
          </a>

          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/billing/rate']">
            Rate
          </a>
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/billing/tariff/category']">
            Tariff Category
          </a> -->
        </div>
      </div>

    </div>



    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" ngbDropdownToggle>
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button type="button" class="btn header-item noti-icon" [routerLink]="['/pages/settings']">
          <i class="bx bx-cog"></i>
        </button>
      </div>
      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell"></i>
          <span class="badge badge-danger badge-pill">3</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">
            <a href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-3.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0"><i
                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                    <p class="mb-0"><i
                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-4.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0"><i
                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </perfect-scrollbar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle mr-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ml-1">Henry</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/pages/profile']"><i
              class="bx bx-user font-size-16 align-middle mr-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-lock-open font-size-16 align-middle mr-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="onSettingsButtonClicked()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->
    </div>
  </div>
</header>
<!-- <div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" (click)="goToPageByLink(item.link)" id="topnav-components"
                role="button">
                <i class="bx {{item.icon}} mr-2"></i>{{ item.label | translate }} <div class="arrow-down"
                  *ngIf="hasItems(item)"></div>
              </a>
              <div class="dropdown-menu row" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a class="col dropdown-item side-nav-link-ref" *ngIf="!hasItems(subitem)" [routerLink]="subitem.link"
                    routerLinkActive="active">{{subitem.label | translate }}</a>

                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a class="dropdown-item" (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" [routerLink]="subSubitem.link"
                          routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>

        </ul>
      </div>
    </nav>
  </div>
</div> -->