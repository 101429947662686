import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { LanguageService } from '../../core/services/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  // set the currenr year
  year: number = new Date().getFullYear();
  countryName;
  cookieValue;
  flagvalue;
  valueset;

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'French', flag: 'assets/images/flags/french.jpg', lang: 'fr' },
  ];

  constructor(
    public languageService: LanguageService, 
    public _cookiesService: CookieService
  ) { }

  ngOnInit() {
    this.cookieValue = this._cookiesService.get('lang');
    console.log(this.cookieValue);
    
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

}
