import { MenuItem } from './menu.model';

export const MENU_RECEPTION: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '',
        // subItems: [
        //     {
        //         id: 2,
        //         label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
        //         link: '/dashboard',
        //         parentId: 1
        //     },
        //     {
        //         id: 3,
        //         label: 'MENUITEMS.DASHBOARDS.LIST.SAAS',
        //         link: '/dashboards/saas',
        //         parentId: 1
        //     },
        //     {
        //         id: 4,
        //         label: 'MENUITEMS.DASHBOARDS.LIST.CRYPTO',
        //         link: '/dashboards/crypto',
        //         parentId: 1
        //     },
        // ]
    },
    {
        id: 2,
        label: 'MENUITEMS.BOOKING_CHART.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
        link: '',
    },
    {
        id: 3,
        label: 'MENUITEMS.APPOINTMENT.TEXT',
        icon: 'bx-customize',
        link: '',
    },
    {
        id: 4,
        icon: 'bx-collection',
        label: 'MENUITEMS.TODAYS_APPOINTMENT.TEXT',
        link: '',
    },
    {
        id: 5,
        label: 'MENUITEMS.REMARKS.TEXT',
        icon: 'bx-file',
        link: '',
    },
    {
        id: 6,
        label: 'MENUITEMS.DOCTOR_REQUESTS.TEXT',
        icon: 'bx-file',
        link: '',
    }
];


export const MENU_BILLING: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '',
    },
    {
        id: 2,
        label: 'MENUITEMS.BILLING.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
        link: '',
    },
    {
        id: 3,
        label: 'MENUITEMS.TRANSACTIONS.TEXT',
        icon: 'bx-customize',
        link: '',
    },
    {
        id: 4,
        label: 'MENUITEMS.UNBILLED_TRANSACTION.TEXT',
        icon: 'bx-collection',
        link: '',
    },
    {
        id: 5,
        label: 'MENUITEMS.IPD_ADD_ITEM.TEXT',
        icon: 'bx-file',
        link: '',
    },
];

export const MENU_ACCOUNTS: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '',
    },
    {
        id: 2,
        label: 'MENUITEMS.BILLING.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
        link: '',
    },
    {
        id: 3,
        label: 'MENUITEMS.TRANSACTIONS.TEXT',
        icon: 'bx-customize',
        link: '',
    },
    {
        id: 4,
        label: 'MENUITEMS.UNBILLED_TRANSACTION.TEXT',
        icon: 'bx-collection',
        link: '',
    },
    {
        id: 5,
        label: 'MENUITEMS.IPD_ADD_ITEM.TEXT',
        icon: 'bx-file',
        link: '',
    },
];

export const MENU_ADMIN: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '',
    },
    {
        id: 2,
        label: 'MENUITEMS.REPORT.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
        link: '',
    }
];

export const MENU_PHARMACY: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '',
    },
    {
        id: 2,
        label: 'MENUITEMS.BILLING.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
        link: '',
    },
    {
        id: 3,
        label: 'MENUITEMS.TRANSACTIONS.TEXT',
        icon: 'bx-customize',
        link: '',
    },
    {
        id: 4,
        label: 'MENUITEMS.UNBILLED_TRANSACTION.TEXT',
        icon: 'bx-collection',
        link: '',
    },
    {
        id: 5,
        label: 'MENUITEMS.IPD_ADD_ITEM.TEXT',
        icon: 'bx-file',
        link: '',
    },
];

export const MENU_DOCTOR: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '',
    },
    {
        id: 2,
        label: 'MENUITEMS.BILLING.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
        link: '',
    },
    {
        id: 3,
        label: 'MENUITEMS.TRANSACTIONS.TEXT',
        icon: 'bx-customize',
        link: '',
    },
    {
        id: 4,
        label: 'MENUITEMS.UNBILLED_TRANSACTION.TEXT',
        icon: 'bx-collection',
        link: '',
    },
    {
        id: 5,
        label: 'MENUITEMS.IPD_ADD_ITEM.TEXT',
        icon: 'bx-file',
        link: '',
    },
];

export const MENU_EMERGENCY: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '',
    },
    {
        id: 2,
        label: 'MENUITEMS.BILLING.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
        link: '',
    },
    {
        id: 3,
        label: 'MENUITEMS.TRANSACTIONS.TEXT',
        icon: 'bx-customize',
        link: '',
    },
    {
        id: 4,
        label: 'MENUITEMS.UNBILLED_TRANSACTION.TEXT',
        icon: 'bx-collection',
        link: '',
    },
    {
        id: 5,
        label: 'MENUITEMS.IPD_ADD_ITEM.TEXT',
        icon: 'bx-file',
        link: '',
    },
];



export const MENU_LAB: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '',
    },
    {
        id: 2,
        label: 'MENUITEMS.BILLING.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
        link: '',
    },
    {
        id: 3,
        label: 'MENUITEMS.TRANSACTIONS.TEXT',
        icon: 'bx-customize',
        link: '',
    },
    {
        id: 4,
        label: 'MENUITEMS.UNBILLED_TRANSACTION.TEXT',
        icon: 'bx-collection',
        link: '',
    },
    {
        id: 5,
        label: 'MENUITEMS.IPD_ADD_ITEM.TEXT',
        icon: 'bx-file',
        link: '',
    },
    {
        id: 2,
        label: 'MENUITEMS.MESSAGES_TASKS.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
    },
    {
        id: 3,
        label: 'MENUITEMS.NEW.TEXT',
        icon: 'bx-customize',
    },
    {
        id: 4,
        label: 'MENUITEMS.CONSUMPTIONS.TEXT',
        icon: 'bx-collection',
        
    },
    {
        id: 5,
        label: 'MENUITEMS.INVENTORY_STOCK.TEXT',
        icon: 'bx-file',
    },
    {
        id: 6,
        label: 'MENUITEMS.SALES_RETURN.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
    },
    {
        id: 7,
        label: 'MENUITEMS.PHARMACY_REPORT.TEXT',
        icon: 'bx-customize',
    },
    {
        id: 8,
        label: 'MENUITEMS.SUB_STORE.TEXT',
        icon: 'bx-collection',
        
    },
    {
        id: 9,
        label: 'MENUITEMS.SALES_ORDER.TEXT',
        icon: 'bx-file',
    },
];


export const MENU_NURSE: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '',
    },
    {
        id: 2,
        label: 'MENUITEMS.BILLING.TEXT',
        icon: 'bx-tone',
        isUiElement: true,
        link: '',
    },
    {
        id: 3,
        label: 'MENUITEMS.TRANSACTIONS.TEXT',
        icon: 'bx-customize',
        link: '',
    },
    {
        id: 4,
        label: 'MENUITEMS.UNBILLED_TRANSACTION.TEXT',
        icon: 'bx-collection',
        link: '',
    },
    {
        id: 5,
        label: 'MENUITEMS.IPD_ADD_ITEM.TEXT',
        icon: 'bx-file',
        link: '',
    },
];
