import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { EventService } from '../../core/services/event.service';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { LanguageService } from '../../core/services/language.service';

import { DOCUMENT } from '@angular/common';

import { MENU_RECEPTION, MENU_BILLING, MENU_ACCOUNTS, MENU_ADMIN, MENU_DOCTOR, MENU_EMERGENCY, MENU_LAB, MENU_NURSE, MENU_PHARMACY } from './menu';
import { MenuItem } from './menu.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss']
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {

  element;
  configData;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  headerItemsReception = [
    { id: 1, route: '/pages/reception' },
    { id: 2, route: 'booking' },
    { id: 3, route: 'appointment' },
    { id: 4, route: 'todays' },
    { id: 5, route: 'remarks' },
    { id: 6, route: 'requests' },
  ]

  headerItemsBilling = [
    { id: 1, route: '/pages/billing' },
    { id: 2, route: 'billing' },
    { id: 3, route: 'transactions' },
    { id: 4, route: 'unbilled' },
    { id: 5, route: 'additem' },

  ]

  headerItemsAccounts = [
    { id: 1, route: '/pages/billing' },
    { id: 2, route: 'billing' },
    { id: 3, route: 'transactions' },
    { id: 4, route: 'unbilled' },
    { id: 5, route: 'additem' },
  ]


  headerItemsAdmin = [
    { id: 1, route: '/pages/billing' },
    { id: 2, route: 'billing' },
    { id: 3, route: 'transactions' },
    { id: 4, route: 'unbilled' },
    { id: 5, route: 'additem' },
  ]


  headerItemsDoctor = [
    { id: 1, route: '/pages/billing' },
    { id: 2, route: 'billing' },
    { id: 3, route: 'transactions' },
    { id: 4, route: 'unbilled' },
    { id: 5, route: 'additem' },
  ]


  headerItemsEmergency = [
    { id: 1, route: '/pages/billing' },
    { id: 2, route: 'billing' },
    { id: 3, route: 'transactions' },
    { id: 4, route: 'unbilled' },
    { id: 5, route: 'additem' },
  ]


  headerItemsLab = [
    { id: 1, route: '/pages/billing' },
    { id: 2, route: 'billing' },
    { id: 3, route: 'transactions' },
    { id: 4, route: 'unbilled' },
    { id: 5, route: 'additem' },
  ]


  headerItemsNurse = [
    { id: 1, route: '/pages/billing' },
    { id: 2, route: 'billing' },
    { id: 3, route: 'transactions' },
    { id: 4, route: 'unbilled' },
    { id: 5, route: 'additem' },
  ]

  headerItemsPharmacy = [
    { id: 1, route: '/pages/pharmacy' },
    { id: 2, route: 'Messages' },
    { id: 3, route: 'New' },
    { id: 4, route: 'Consumptions' },
    { id: 5, route: 'Inventory' },
    { id: 6, route: 'Sales' },
    { id: 7, route: 'Report' },
    { id: 8, route: 'Sub' },
    { id: 9, route: 'Order' },
  ]

  menuItems = [];
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(DOCUMENT) private document: any, 
    private router: Router, 
    private eventService: EventService, 
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService, 
    public languageService: LanguageService, 
    public _cookiesService: CookieService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });
  }

  ngOnInit(): void {
    this.element = document.documentElement;

    this.initialize();

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  goToPageByLink(link) {
    this.router.navigate([link]);

    // let opt = this._cookiesService.get('section');
    // if (opt === 'reception') {
    //   this.headerItemsReception.forEach(element => {
    //     if (element.id === id) {
    //       this.router.navigate([link]);
    //     }
    //   });
    // }
    // else if (opt === 'billing') {
    //   this.headerItemsBilling.forEach(element => {
    //     if (element.id === id) {
    //       this.router.navigate([element.route]);
    //     }
    //   });
    // }
  }

  goToPatientList() {
    this.router.navigate(['/pages/patient/list']);
  }

  goToNewPatient() {
    this.router.navigate(['/pages/patient/new']);
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(['/auth/login']);
  }

  /**
   * On menu click
   */
  onMenuClick(event: any) {
    const nextEl = event.target.nextSibling;
    if (nextEl && !nextEl.classList.contains('show')) {
      const parentEl = event.target.parentNode;
      if (parentEl) { parentEl.classList.remove('show'); }

      nextEl.classList.add('show');
    } else if (nextEl) { nextEl.classList.remove('show'); }
    return false;
  }

  ngAfterViewInit() {
    this.activateMenu();
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById('topnav-menu-content');
    element.classList.toggle('show');
  }

  /**
   * Activates the menu
   */
  private activateMenu() {

    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('active');
              }
            }
          }
        }
      }
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Topbar light
   */
  topbarLight() {
    document.body.setAttribute('data-topbar', 'light');
    document.body.removeAttribute('data-layout-size');
  }

  /**
   * Set boxed width
   */
  boxedWidth() {
    document.body.setAttribute('data-layout-size', 'boxed');
    document.body.setAttribute('data-topbar', 'dark');
  }

  /**
   * Colored header
   */
  coloredHeader() {
    document.body.setAttribute('data-topbar', 'colored');
    document.body.removeAttribute('data-layout-size');
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    this.eventService.broadcast('changeLayout', layout);
  }

  /**
   * Initialize
   */
  initialize(): void {
    let opt = this._cookiesService.get('section');
    if (opt === 'reception') {
      this.menuItems = MENU_RECEPTION;
    } else if (opt === 'billing') {
      this.menuItems = MENU_BILLING;
    } else if (opt === 'accounts') {
      this.menuItems = MENU_ACCOUNTS;
    } else if (opt === 'admin') {
      this.menuItems = MENU_ADMIN;
    } else if (opt === 'doctor') {
      this.menuItems = MENU_DOCTOR;
    } else if (opt === 'emergency') {
      this.menuItems = MENU_EMERGENCY;
    } else if (opt === 'lab') {
      this.menuItems = MENU_LAB;
    } else if (opt === 'nurse') {
      this.menuItems = MENU_NURSE;
    } else if (opt === 'pharmacy') {
      this.menuItems = MENU_PHARMACY;
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
  
  gotoDesk() {
    this.router.navigate(['/']);
  }

}
